export default {
  //statics
  title: "Title",
  rate_name: "Rate",
  reservations: "Bookings",
  turkish: "Turkish",
  english: "English",
  new_reservation: "New Reservation",
  search: "Search",
  pickup_location: "Pickup Location",
  pickup_time: "Pick-up Date / Time",
  promo_code: "Promo Code",
  return_location: "Dropoff Location",
  return_time: "Drop-off Date / Time",
  turkish_currency: "Turkish Currency",
  american_currency: "American Currency",
  brand: "Brand",
  class: "Class",
  size: "Size",
  gear: "Gear",
  fuel: "Fuel",
  fuel_policy: "Fuel Policy",
  seat: "Seat",
  driver_age: "Driver Age",
  km_limit: "KM Limit",
  unlimited: "Unlimited",
  company: "Company",
  phone: "Phone",
  office: "Office",
  address: "Address",
  daily_price: "Daily Price",
  provision: "Deposit",
  total_payment_price: "Total Price",
  close: "Close",
  general: "General",
  age: "Age",
  driving_license_period: "Driving License Period",
  yearly: "Yearly",
  pickup: "Pickup",
  return: "Return",
  day: "Day",
  per_rental: "Per Rental",
  loading: "LOADİNG...",
  no_additional_products_found:
    "No extras found for this company. You can purchase extras when you go to the branch.",
  driver_info: "Driver Info",
  search_for_registered_driver: "Search For Registered Driver",
  search_user: "Search User",
  remove: "Remove",
  name: "Name",
  driver_name: "Driver Name",
  driver_lastname: "Driver Lastname",
  lastname: "Lastname",
  mobile_phone: "Mobile Phone",
  email: "Email",
  date_of_birth: "Birthday",
  tc_identity: "Identity Number",
  passport: "Passport Number",
  billing_information: "Billing Information",
  reservation_date_change: "Reservation Date Change",
  billing_type: "Billing Type",
  province: "Province",
  post_code: "Post Code",
  tax_office: "Tax Office",
  tax_number: "Tax Number",
  tax_tc: "Tax / TC No",
  company_title: "Company Title",
  brand: "Brand",
  select_country: "Select Country",
  country: "Country",
  flight_number: "Flight Number",
  payment: "Payment",
  reservation_type: "Reservation Type",
  payment_status: "Payment Status",
  payment_type: "Payment Type",
  payment_date: "Payment Date",
  rental_price: "Rental Price",
  total_extras: "Total Extras",

  drop_off_price: "Drop Off Price",
  office_service_fee: "Office Service Fee",
  now_payment_price: "Now Payment Price",
  delivery_payment_price: "Delivery Payment Price",
  total_payment_price2: "Total Payment Price",
  excluding_deposit: "Without Deposit",

  deposit: "Deposit",
  cancel: "Cancel",
  continue: "Continue",
  complete_the_reservation: "Complete Reservation",
  warning: "WARNING",
  warnin_info:
    "This reservation price will be calculated according to the percentage entered in the rates section, do you confirm?",
  accept: "Accept",
  phone_number: "Phone Number",

  virtual_pos: "Virtual Pos",
  payment_via_sms_link: "Payment Via Sms Link",
  current_account: "Current Account",

  individual: "Individual",
  individualCompany: "Individual Company",
  company: "Supplier",
  abroad: "Abroad",
  abroadCompany: "Abroad Company",

  image: "Image",
  company_name: "Company Name",
  campaign: "Offer",
  boarding_pass_obligation: "Boarding Pass",
  compulsory: "Required",
  compulsory_not: "Not Required",
  daily: "Daily",
  total: "Total",
  driving_license: "Driving License",
  delivery: "Delivery",
  person: " People",
  columns: "Columns",

  filters: "Filters",

  enter_name_and_surname: "Enter Name And Surname",
  enter_card_number: "Enter Card Number",
  date: "Date",
  passive: "Passive",

  pending: "Pending",
  last_search: "Last Search",
  blocked: "Blocked",
  status: "Status",
  status_early_return: "Early Return",
  identity_type: "Indetity Type",
  okey: "Okay",
  select_user: "Select User",
  select_driver: "Select Driver",
  copy: "Copy",
  find_car: "Find Car",
  not_found_user: "Not Found User",
  users: "Users",
  wanted: "Wanted",
  user_info1: " user named.",
  profil: "Profile",
  exit: "Log-off",

  driver_form: "Driver Form",
  delete_driver_form_info:
    "You are removing the user. Do you want to clear the driver form as well?",
  yes: "Yes",
  not: "Not",
  no: "Hayır",
  drivers: "Drivers",
  drivers_not_found: " no registered driver found!",
  selected_user: " selected user",
  area: " area",
  check_the_form: "Check The Form",
  reservation_detail: "Reservation Detail",
  reservation_completed: "Reservation Completed",
  go_to_reservation: "Go to Reservation",
  reservation_success_email_info:
    " coded reservation has been added to the system and the reservation details have been sent to the user e-mail address.",
  reservation_success_sms_info:
    " coded reservation has been added to the system and the reservation details have been sent to the user phone number.",

  reservation: "Reservation",
  caution: "Caution!",

  exchange_rate: "Exchange Rate",

  sales: "Sales",
  discount: "Discount",
  daily_rental_fee: "Daily Price",
  retailDailyRentalPrice: "Retail Daily Price",
  retailTotalRentalPrice: "Retail Total Price",
  total_extra_fee: "Total Extras",
  reservation_currency: "Reservation Currency",
  supplier_daily_currency_amount: "Supplier Daily Currency Amount",
  supplier_total_currency_amount: "Supplier Total Currency Amount",
  customer_daily_currency_amount: "Customer Daily Currency Amount",
  customer_total_currency_amount: "Customer Total Currency Amount",

  amount_payable_on_delivery: "Amount Payable on Delivery",
  total_fee: "Total Price",
  identity: "Identity",

  years_old: "years old",
  type: "Type",

  invoiced: "Invoiced",
  error_t: "Error",
  invoice_number: "Invoice Number",
  invoice_date: "Invoice Date",
  payment_info: "Payment Info",
  description_to_supplier: "Description to Supplier",
  amount_paid: "Amount Paid",
  advance: "Advance",
  installment: "Installment",
  order_number: "Order Number",
  bank: "Bank",
  card_number: "Card Number",
  card_type: "Card Type",
  local: "Local",
  reservation_number: "Reservation No",
  return_status: "Return Status",
  vendor_reservation_number: "Vendor Reservation No",
  search_date: "Search Date",
  process_date: "Process Date",
  trader: "Trader",
  cancel_date: "Cancel Date",
  cancel_time: "Cancel Hours",
  cancel_author: "Cancel Author",
  rental_day_range: "Rental Day Range",
  survey_link: "Survey Link",

  reason_for_cancellation: "Reason For Cancellation",
  send_reservation_email: "Send Reservation Email",
  forward_call_ivr: "Forward Call IVR",
  download_reservation_document: "Download Reservation Document",
  early_return_request: "Early Return Request",
  early_return_registration: "Early Return Registration",
  early_return_rejection: "Early Return Rejection",
  refund_payment: "Refund Payment",
  noshow_refund_payment: "NoShow Refund Payment",
  partial_refund_fee_payment: "Partial Refund Fee Payment",
  full_refund_fee_payment: "Full Refund Fee Payment",
  refund_payment_amount: "Refund Payment Amount",
  early_return_confirmation: "Early Return Confirmation",
  send_survey: "Send Survey",
  res_date_change: "Change Reservation Date",
  cancel_btn: "Cancel",
  change: "Change",

  pay_with_virtual_pos: "Pay With Virtual Pos",
  payments: "Payments",
  channel: "Channel",

  amount: "Amount",
  early_return: "Early Return",
  collection: "Collection",
  transferred: "Transferred",
  early_return_btn: "Early Return",

  pos_transactions: "POS Transactions",
  process_t: "Process",
  message: "Message",
  card: "Card",
  agent_note: "Agent Notes",
  reservation_survey: "Reservation Survey",
  early_return_info: "Early Return Info",
  returned_day: "Returned Day",
  returned_fee: "Returned Fee",
  returned_date: "Dropoff Date",

  date_of_registratio: "Date of Registratio",

  type_of_early_returner: "Type of Early Returner",
  early_return_author: "Early Return Author",

  early_return_reason: "Early Return Reason",
  early_return_date: "Early Return Date",
  early_return_amount: "Early Return Amount",
  early_return_description: "Select the Start Date of the Return Process",

  the_fee_charged: "The Fee Charged",
  rental_period: "Rental Period",
  financial_rejection_reason: "Financal Rejection Reason",
  rejection_desc: "Please share your reason for cancellation with us.",
  rejection_desc3: "You Cancel Reservation Number",

  other: "Other",

  your_explanation: "Your Explanation",
  leave: "Leave",
  send: "Send",
  early_return_info2: "You are returning the reservation numbered early.",
  early_return_info3: "Please share your reason for early return with us",
  early_return_form: "Early Return Form",
  card_number2: "Card Number",
  owner_of_the_card_number: "Owner of The Card Number",
  month: "Month",
  year: "Year",
  secure_code: "Secure Code",

  complete_payment: "Complete Payment",
  reservation_settings: "Reservation Settings",
  reservation_type: "Reservation Type",
  select_reservation_type: "Select Reservation Type",
  spending_objection: "Spending Objection",
  select_spending_objection: "Select Spending Objection",
  update: "Update",
  there_is: "There Is",
  none: "None",
  invalid_parameters: "Invalid Parameters",
  comments: "Comments",
  comment_average: "Comment Average",
  is_comment_sent: "Is Comment Sent",
  i_approve: "I Approve",
  early_return_confirmed: "Early Return Confirmed",
  early_return_denied: "Early Return Denied",
  early_return_register_info: "Early Return Registration successfully received",
  approve_email_desc: "Confirmation Email sent successfully",
  wait: "Processing Please Wait",
  cancel_success_info: "Cancellation was successful",
  search_t: "Search...",
  notifications: "Notifications",
  send_notification: "Send Notification",
  fullCredit_billing_status: "Full Credit Billing Status",

  approved: "Approved",
  pending_t: "Pending",
  active: "Active",
  completed: "Completed",
  cancelled: "Cancelled",
  billed: "Billed",
  notbilled: "Not Billed",
  canceledbill: "Cancelled Bill",
  earlyReturnRequest: "Early Return Request",
  earlyReturnRequestWaitConfirm: "Early Return Request Wait Confirm",
  paymentError: "Payment Error",
  all: "All",
  partner_payment: "Partner Payment",
  paid: "Paid",

  it_is_cancelled: "It is Cancelled",
  vendor: "Vendor",
  user_type: "User Type",
  quest_member: "Guest",
  registered_member: "Registered",
  unknown: "Unknown",
  reservation_date: "Date",
  reservation_hour: "Hour",

  error_reason: "Error Reason",
  rental_days: "Days",
  installment_count: "Installment Count",
  vendor_total_rental_price: "Vendor Total Rental Price",
  interest: "Interest",

  returned: "Returned",
  purchase_date: "Pickup Date",
  invoice_status: "Invoice Status",

  with_a_campaign: "With a Campaign",
  campaign_name: "Campaign Name",
  campaign_discount_rate: "Campaign Discount Rate (%)",
  campaign_vendor_commision_rate: "Campaign Vendor Commision Rate (%)",
  delivery_office: "Delivery Office",
  return_office: "Return Office",
  differen_returns: "Different Returns",

  different_return_amount: "Drop Price",
  operating_user_type: "Operating User Type",
  driver_email: "Driver Email",
  driver_phone: "Driver Phone",
  future_reservations: "Approved",
  ongoing_reservations: "Continuing Reservations",
  past_reservations: "Completed",

  canceled_reservations: "Cancelled Reservations",
  invoiced_reservations: "Invoiced Reservations",
  not_invoiced_reservations: "Unbilled Reservations",
  invoiced_cancel_reservations: "Cancelled Invoice Reservations",
  noShowCancelled: "No-Show Cancelled",

  early_return_requests: "Early Return Requests",
  early_return_awaiting_approval: "Early Returns Awaiting Approval",
  condition_partner_paid: "Condition Partner Paid",
  coming_soon: "Coming Soon",
  find_a_car: "Find an car",

  today: "Today",
  yesterday: "Yesterday",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  date: "Date",
  metropol_card_no: "Metropol Card Number",
  metropol_card_no_eror: "Check Your Metropol Card Number.",
  invoices_transfer: "Invoices Transfer",
  payment_transfer: "Payment Transfer",
  city: "City",
  contract: "Contract",

  //sidebar
  reservationOfPartner: "Reservation of Partner",
  sidebar: {
    search_nb: "Search",
    home_page: "Home",
    make_a_reservation: "New Reservation",
    make_a_reservation_site: "New Reservation (Site)",
    all_reservations: "All Reservations",
    recent_searches: "Recent Searches",
    continuning_reservations: "Continuing",
    future_reservations: "Approved",
    past_reservations: "Completed",
    cancelled_reservations: "Cancelled",
    no_show_cancelled_reservations: "No-Show",
    payment_errors: "Payment Errors",
    rates: "Rates",
    rates_desc: "...",
    rates_save: "Save Rates",
    rate_input_label: "Overall Price Rate",

    user: "Users",
    members: "Members",
    managers: "Managers",
    partner_users: "Partner Users",
    company_users: "Company Users",
    testers: "Test Users",
    mernis_control: "Mernis Control",
    group_authorization: "Group Authorization",
    account_deletion_requests: "Account Deletion Requests",
    campanies: "Companies",
    campanies_location: "Company Locations",
    campaines_cars: "Company Cars",
    partners: "Partners",
    partner: "Partner",
    reservations: "Reservations",
    all: "All",
    last_search: "Last Search",
    early_return_req: "Early Return Requests",
    early_return_req_financial_confirm: "Early Returns Awaiting Approval",
    early_return_successed: "Confirmed Early Return",
    consensus: "Consensus",
    cars: "Cars",
    locations: "Locations",
    city_locations: "City Locations",
    comments: "Comments",
    campaign: "Campaigns",
    coupons: "Coupons",
    send_notification: "Send Notification",
    settings: "Settings",
    mss: "DSC",
    contracts: "Contracts",
    questions: "Questions",
    partners_work: "Work partners",
    montly_rental_page: "Monthly Rental Page",
    accounting: "Accounting",
    invoices: "Invoices",
    payments: "Payments",
    categorys: "Categorys",
    questions: "Questions",
  },
  //header
  header: {
    select_lang: "Language",
    select_currency: "Currency",
  },
  //dashboards
  dashboard: {
    supplier_based_reservations: "Bookings",
    reservation_status: "Reservation Status"
  },
  //alert
  alert: {
    success: "{name} update successful.",
    error: "{name} could not be updated.",
    no_records_found: "No Records Found",
    select_pickup: "Please Select a Pickup",
    select_return: "Please Select a Return",
    select_pickup_time: "Please select a date of purchase.",
    select_return_time: "Please select a return date.",
    select_pickup_date_warning: "Receipt Date can be at least {date}.",
    select_pickup_date_warning2: "Receipt Date can be {date} at most.",
    select_pickup_date_warning3:
      "Receipt date cannot be greater than or equal to the return date!",
    metropol_card_no_eror: "Please Check Your Metropol Card Number",
  },
  warning: {
    driver_empty:
      "Please do not leave any blank spaces in the driver information.",
    cancel_empty: "Please select the reason for cancellation.",

    payment_type_empty: "Please choose Payment Type!",
  },
  description: {
    spending_objection:
      "A spending objection has been made to this reservation.",
    spending_desc:
      "This offer has not yet turned into a reservation, you can pay to complete the reservation.",
  },
  INVALID_PICKUP_DATE:
    "The selected purchase date cannot be less than the current date!<br>Please try again.",
  INVALID_DELIVERY_DATE:
    "The selected return date cannot be less than or equal to the purchase date!<br>Please try again.",
  INVALID_PROMO_CODE: "The promo code entered is invalid.",
  ARAMA_TALEBI_OLUSTURULMADI:
    "Failed to create search request! Please try again.",
  DEFAULT: "Please Try Again.",
  PARTNER_CANT_USE: "You have not permission to use this promo code.",
  INVALID_PROMO_DATES:
    "The promo code you entered is not valid between these dates",
  normal: "Normal",
  fullCredit: "Full Credit",
  payOnArrival: "Pay On Arrival",
  partialPayment: "Partial Payment",
  phoneFormatErr: "Phone Verification Error",
  apply: "Apply",
  give_up: "Give Up",
  damage_exemption: "Damage Exemption",
  theft_protection: "Theft Production",
  third_party_Coverage: "Third Party Coverage",
  unlimited_mileage: "Unlimited Mileage",
  free_cancellation: "Free Cancellation",
  free_damage_assistance: "Free Damage Assistance",
  EMPTY_OBJECT: "Please Enter Filter Parameter",
  tc_or_psp: "Identity Number",
  vendor_choose: "choose",
  campaigns: "Campaigns",
  reservation_date_range: "Reservation Date Range",
  vehicle_delivery_date_range: "Vehicle Purchase Date Range",
  vehicle_return_date_range: "Vehicle Return Date Range",
  cancel_date_range: "Cancelled Date Range",
  permissions: "Permissions",
  canceling_user_type: "Canceling User Type",
  partnerTypes: {
    agent: "Agent",
    partner: "Partner",
    corporate: "Corporate",
    replacement: "Replacement",
    overseasBroker: "Overseas Broker",
    domesticBroker: "Domestic Broker",
  },
  profil_menu_t1: "Do you want to log out?",
  free_cancel_text: "Up to %{minHoursInfo} hours",
  early_return_1: "Early Return",
  free_cancel_alert:
    "In case of cancellation; if there is less than %{minHoursInfo} hours to the vehicle purchase date, %{payCutDay} day rental fee will be charged.",
  early_return_alert:
    " Early Returns are Not Accepted by %{vendorName} Company.",
  free_cancel_alert_2:
    "In case of cancellation; if there is less than %{minHoursInfo} hours before the pick-up time, the entire reservation fee will be charged.",
  available: "Available",
  no_show_cancel_alert:
    "No Fee Refund is Not Made in No-Show Cancellation by asd %{vendorName} ",
  go_button: "GO",
};
