<template>
  <v-app>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {},
  mounted() {
    // if (this.$store.state?.GetServerData?.userType === "partner") {
    //   document.title = "Renticar Partner";
    // } else {
    //   document.title = "Renticar Admin";
    // }
  },
};
</script>
<style lang="scss">
// Import Main styles for this appli cation
@import "assets/scss/style";
</style>
