import "core-js";
import Vue from "vue";
import CoreuiVue from "@coreui/vue-pro/src";

import { iconsSet as icons } from "./assets/icons/icons.js";

import App from "./App";
import router from "./router";
import store from "./store";

import vuetify from "@/plugins/vuetify"; // path to vuetify export
import i18n from "@/plugins/i18n.js";
import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);
import { Drag, Drop } from "vue-drag-drop";

Vue.component("drag", Drag);
Vue.component("drop", Drop);
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import * as VueGoogleMaps from "vue2-google-maps";

moment.locale(store.state.locale || "tr");
moment.tz.setDefault("UTC");
Vue.use(CoreuiVue);
Vue.use(VueMoment, {
  moment,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places",
  },
});

Vue.config.performance = true;
Vue.prototype.$log = console.log.bind(console);

// ##### VeeValidate ##### - https://vee-validate.logaretm.com/v3/overview.html#installation

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/tr.json";
import * as rules from "vee-validate/dist/rules";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Object.keys(rules).forEach((rule) => {
  if (rule == "required") {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: "Eksik veya hatalı alan.", // assign message
    });
  } else if (rule == "email") {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: "Geçerli bir e-posta olması gerekli.", // assign message
    });
  } else {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule], // assign message
    });
  }
});

extend("oneUpperCase", {
  validate: (value) => {
    const regLg = new RegExp("^(?=.*[A-Z])");
    return regLg.test(value);
  },
  message: "{_field_} en az bir büyük harf içermelidir.",
});

extend("oneLowerCase", {
  validate: (value) => {
    const regSm = new RegExp("^(?=.*[a-z])");
    return regSm.test(value);
  },
  message: "{_field_} en az bir küçük harf içermelidir.",
});

extend("oneNumber", {
  validate: (value) => {
    const regNum = new RegExp("(?=.*[0-9])");
    return regNum.test(value);
  },
  message: "{_field_} en az bir rakam içermelidir.",
});

// ##### VueSweetalert2 ##### - https://sweetalert2.github.io/
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

// ##### VueProgressBar ##### - https://github.com/hilongjw/vue-progressbar
import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  height: "2px",
});
// ##### FontAwesomeIcon #####
// Not: Fontawesome'dan eklenecek olan ikonları seçip, library'e set ediyoruz.
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowsAlt,
  faCloudUploadAlt,
  faTrashAlt,
  faChevronUp,
  faCarSide,
  faLocationArrow,
  faMapMarker,
  faCaretSquareDown,
  faChevronDown,
  faBookmark,
  faComment,
  faBars,
  faCar,
  faInfoCircle,
  faHandHoldingUsd,
  faClock,
  faShieldAlt,
  faPlus,
  faMinus,
  faCalendarAlt,
  faCircleNotch,
  faMoneyBillWave,
  faReceipt,
  faUser,
  faLock,
  faUserCircle,
  faSignOutAlt,
  faBuilding,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCopy,
  faPlusSquare,
  faCreditCard,
  faIdCard,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add({
  faArrowsAlt,
  faCloudUploadAlt,
  faTrashAlt,
  faChevronUp,
  faCarSide,
  faMapMarker,
  faLocationArrow,
  faChevronDown,
  faCaretSquareDown,
  faBookmark,
  faComment,
  faBars,
  faCar,
  faInfoCircle,
  faHandHoldingUsd,
  faClock,
  faShieldAlt,
  faPlus,
  faMinus,
  faCalendarAlt,
  faCircleNotch,
  faMoneyBillWave,
  faReceipt,
  faUser,
  faLock,
  faUserCircle,
  faSignOutAlt,
  faBuilding,
  faUserLock,
  faCopy,
  faPlusSquare,
  faCreditCard,
  faIdCard,
});
Vue.component("font-awesome-icon", FontAwesomeIcon);

// ##### AG GRID  #####
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-enterprise";

// ##### VueCtkDateTimePicker #####
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

// ##### VueClipboard ##### - https://www.npmjs.com/package/vue-clipboard2
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// ##### VueTelInput ##### - https://www.npmjs.com/package/vue-tel-input (Telefon Numarası Input)
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput);

//v-mask
import VueMask, { VueMaskDirective, VueMaskFilter } from "v-mask";
Vue.use(VueMask);

// Or as a directive-only
Vue.directive("mask", VueMaskDirective);

// Or only as a filter-only
Vue.filter("VMask", VueMaskFilter);

import { VTooltip } from "v-tooltip";
Vue.use(VTooltip);
Vue.directive("my-tooltip", VTooltip);

import VueGtm from "@gtm-support/vue2-gtm";
Vue.use(VueGtm, {
  id: process.env.NODE_ENV === "production" ? "GTM-TRTJ326" : "GTM-KLVWTXW",
  defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router,
  vuetify,
  store,
  icons,
  i18n,

  template: "<App/>",
  components: {
    App,
  },
  watch: {
    "$i18n.locale": function () {
      this.$store.commit("setLanguage");
    },
  },
  created() {
    this.$store.commit("setLanguage");
    this.$store.state.isLoading = false;
  },
  mounted() {
    this.$store.commit("setLanguage");
  },
});
